import ArticleImg01 from '../../img/ArticleImg/Article01.jpg'
import ArticleImg03 from '../../img/ArticleImg/Article03.jpg'
import ArticleImg04 from '../../img/ArticleImg/Article04.jpg'
import ArticleImg05 from '../../img/ArticleImg/Article05.jpg'
import ArticleImg06 from '../../img/ArticleImg/Article06.jpg'
import ArticleImg07 from '../../img/ArticleImg/Article07.jpg'
import ArticleImg08 from '../../img/ArticleImg/Article08.jpg'
import ArticleImg09 from '../../img/ArticleImg/Article09.jpg'
import ArticleImg10 from '../../img/ArticleImg/Article10.jpg'
import ArticleImg11 from '../../img/ArticleImg/Article11.png'
import ArticleImg12 from '../../img/ArticleImg/Article12.jpg'
import ArticleImg12_2 from '../../img/ArticleImg/olymp-logo.png';
import ArticleImg13 from '../../img/ArticleImg/Article13.jpg'
import ArticleImg13_2 from '../../img/ArticleImg/Article13_2.jpg';
import ArticleImg13_3 from '../../img/ArticleImg/Article13_3.jpg';
import ArticleImg14 from '../../img/ArticleImg/Article14.jpg'
import ArticleImg15 from '../../img/ArticleImg/Article15.png'
import ArticleImg15_2 from '../../img/ArticleImg/Article15_2.png'
import ArticleImg15_3 from '../../img/ArticleImg/Article15_3.png'
import ArticleImg16 from '../../img/ArticleImg/Article16.jpg'
import ArticleImg17 from '../../img/ArticleImg/Article17.jpg'
import ArticleImg18_1 from '../../img/ArticleImg/Article18_1.png'
import ArticleImg18_2 from '../../img/ArticleImg/Article18_2.png'
import ArticleImg19 from '../../img/ArticleImg/Article19.jpg'
import ArticleImg19_1 from '../../img/ArticleImg/Article19_1.png'
import ArticleImg19_2 from '../../img/ArticleImg/Article19_2.jpg'
import ArticleImg19_3 from '../../img/ArticleImg/Article19_3.png'
import ArticleImg19_4 from '../../img/ArticleImg/Article19_4.jpg'
import ArticleImg19_5 from '../../img/ArticleImg/Article19_5.jpg'

const articlesData = [

    {
        slug: 'article01',
        author: 'Тимофей Стрелков',
        date: '2023-08-25',
        title: 'Cколько чаевых оставляют россияне в салонах красоты',
        summary: 'Как изменился размер чаевых в бьюти-заведениях с начала 2023 и по итогам августа.',
        imageUrl: ArticleImg01,
        content1: 'Культура чаевых становится всё более широко распространённой в России. Популярной практикой стало оставлять чаевые не только в ресторанах, но и в салонах красоты, барбершопах, массажных студиях.',
        content2: 'Чаще всего россияне оставляют чаевые в Москве и Петербурге.',
        content3: 'С начала 2023 года количество салонов красоты, использующих безналичные чаевые, увеличилось на 42%. Средний размер чаевых вырос на 9% и составил 306 рублей.',
        content4: 'Общая сумма чаевых в августе 2023',
        content5: 'В рейтинге направлений на первом месте ожидаемо мастера салонов красоты. Но при этом средний размер чаевых распределился немного по-другому: в тату-студиях средний чек на 78% больше, чем у мастеров салонов красоты, а в косметологических клиниках — на 36%. В то время как у барбершопов наоборот — на 11% меньше.',
        content6: 'Топ-3 направлений по сумме полученных чаевых (август 2023)',
        content7: '1. Салон красоты',
        content8: '2. Барбершоп',
        content9: '3. SPA',
        content10: 'Топ-8 направлений по среднему чеку чаевых (август 2023)',
        content11: '1. Москва',
        content12: '2. Санкт-Петербург',
        content13: '3. Екатеринбург',
        content14: '4. Нижний Новгород',
        content15: '5. Ростов-на-Дону',
        content16: '6. Краснодар',
        content17: '7. Новосибирск',
        content18: '8. Самара',
        content19: '9. Тула',
        content20: '10. Уфа',
        content21: 'Среди городов России чаще всего оставляют чаевые в таких крупных городах, как Москва, Санкт-Петербург и Екатеринбург. При этом если смотреть на средний размер чаевых, то первые три позиции остаются без изменений, а дальше рейтинг сильно меняется. Например, Нижний Новгород и Уфа поменялись местами: первый был на 4 месте по частоте, а если смотреть на средний чек, то смещается на 10 место.',
    },
    {
        slug: 'article03',
        author: 'Игорь Климов',
        date: '2023-07-20',
        title: 'Аренда: что критично сделать уже сейчас',
        summary: 'Чек-лист взаимодействий с арендодателями и меры поддержки',
        imageUrl: ArticleImg03,
        content1: 'В условиях всеобщего карантина возникает большое количество вопросов, связанных с предпринимательской деятельностью. В данной статье мы разберем один из ключевых вопросов: аренду.',
        content2: 'ЧЕК-ЛИСТ по взаимодействию с арендодателями:',
        content3: '1. Направить Уведомление арендодателю. Опишите текущую сложную ситуацию, сошлитесь на форс-мажор и просите именно каникулы от выплаты аренды.',
        content4: '2. В случае отсутствия компромисса, вы можете не платить аренду и ссылаться на Постановление правительства от 31 марта -номер 273-ПП, где в пункте 5 — указано — что у Вашего арендодателя есть возможность не платить налог на имущество, земельный налог — если он снизит арендную ставку не менее, чем на 50 процентов.',
        content5: '3. ПЕНИ, ШТРАФЫ за неуплату аренды начисляться не могут. Выселить Вас тоже не могут (так как это судебный процесс — а они все приостановлены на период карантина)',
        content6: 'Арендодатель- тот же предприниматель, который несет убыток и только партнерские переговоры уместны в данном случае.',
        content7: 'В соответствии с п. 3 ст. 401 ГК РФ если иное не предусмотрено законом или договором, лицо, не исполнившее или ненадлежащим образом исполнившее обязательство при осуществлении предпринимательской деятельности, несет ответственность, если не докажет, что надлежащее исполнение оказалось невозможным вследствие непреодолимой силы, то есть чрезвычайных и непредотвратимых при данных условиях обстоятельств.',
        content8: 'В силу п. 8 Постановления Пленума В С РФ от 24.03.2016 № 7 для признания обстоятельства непреодолимой силой необходимо, чтобы оно носило чрезвычайный и непредотвратимый при данных условиях характер.',
        content9: 'В связи с тем, что ГК РФ не относит к обстоятельствам непреодолимой силы, в частности, нарушение обязанностей со стороны контрагентов должника, отсутствие на рынке нужных для исполнения товаров, отсутствие у должника необходимых денежных средств, самым сложным в использовании механизма освобождения от ответственности в условиях пандемии — это доказывание причинно-следственной связи между невозможностью исполнения и/или ненадлежащим исполнением и мерах реагирования на распространение новой коронавирусной инфекции (COVID-19).',
        content10: 'Прямая связь между большинством запретов и ограничений, например, рекомендация по переходу к удаленному режиму работы, запрет на проведение мероприятий с присутствие более 50 человек и т. п., и невозможностью своевременно вносить арендную плату отсутствует. Как правило, причиной является отсутствие необходимых денежных средств из-за падения размера выручки, снижения спроса и иных признаков тяжелой экономической ситуации на рынке, что исключает допустимость освобождения от ответственности.',
        content11: 'При обосновании причинно-следственной связи в любом случае рекомендуется ссылаться на то, что режим повышенной готовности влечет за собой полную или частичную невозможность использования арендуемого имущества по назначению или в согласованных с арендодателем целях.',
        content12: 'Очень важно отметить, что непреодолимая сила освобождает должника лишь от ответственности за нарушение обязательства (неустойка), но не от самой арендной платы.',
        content13: 'В силу п. 1 ст. 615 ГК РФ арендатор обязан пользоваться арендованным имуществом в соответствии с условиями договора аренды, а если такие условия в договоре не определены, в соответствии с назначением имущества. Соответственно, если положения договора аренды позволяют установить, что арендатор, заключая этот договор, намерен был использовать и использовал имущество с целью той или иной предпринимательской деятельности, осуществление которой в соответствии с установленным режимом повышенной готовности запрещено или существенно ограничено, арендатор, теряя возможность пользования по назначению, должен быть освобожден от ответственности за просрочку внесения арендной платы.',

    },
    {
        slug: 'article04',
        author: 'Максим Глинский',
        date: '2023-06-21',
        title: 'Этапы снятия ограничений работы предприятий',
        summary: 'Информация по открытию организаций на каждом этапе. Порядок определения перехода к определенному этапу в регионах',
        imageUrl: ArticleImg04,
        content1: '8 мая А. Ю. Поплова, руководитель федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека, Главный государственным врач РФ, утвердила этапы снятия ограничений',
        content2: 'Предполагается возобновление деятельности предприятий и организаций в три этапа, деятельность которых связана с потребителями.',
        content3: 'На первом этапе возобновляется работа предприятий из сферы услуг и сферы торговли непродовольственными товарами. Вводятся ограничения на площади открываемых объектов торговли и предельное количество лиц, которые могут одновременно находиться в торговом зале.',
        content4: 'На втором этапе возобновляется работа предприятий торговли непродовольственными товарами с увеличением площади открываемого объекта, а также иные форматы торговли, открытие отдельных образовательных организаций. Сохраняется ограничение на предельное количество лиц, которые одновременно могут находиться в одном помещении.',
        content5: 'На последнем этапе открывается работа предприятий торговли и сферы услуг без ограничений на количество посетителей, предприятий общественного питания, образовательных организаций, гостиниц и мест отдыха.',
        content6: 'На всех этапах сохраняется:',
        content7: '1. Режим самоизоляции для людей с высоким риском заболевания',
        content8: '2. Сохранение работы в удаленном формате, если это не мешает функционированию предприятия',
        content9: '3. Использование гигиенических масок',
        content10: '4. Соблюдение масочного режима всеми работающими',
        content11: '5. Соблюдение дезинфекционного режима',
        content12: '6. Соблюдение социального дистанцирования',
        content13: 'Режим работы предприятий определяется Высшими должностными лицами субъектов РФ с учетом обстановки. Решение о снятии всех ограничений принимается в каждом субъекте РФ отдельно после стабилизации эпидемиологической ситуации.',
    },
    {
        slug: 'article05',
        author: 'Тимофей Стрелков',
        date: '2023-06-03',
        title: 'Чек-лист: рекомендации салонам после окончания режима самоизоляции',
        summary: 'Рекомендации Роспотребнадзора по организации работы салонов',
        imageUrl: ArticleImg05,
        content1: 'Роспотребназдор опубликовал список рекомендаций для салонов красоты и парикмахерских: что следует предпринять руководителям после окончания режима самоизоляции',
        content2: '1. Перед открытием салона красоты провести генеральную уборку помещений с применением дезинфицирующих средств.',
        content3: '2. Ежедневно, перед началом работы, проводить «входной фильтр» для работников:',
        content4: 'Бесконтактное измерение температуры тела, отсутствие признаков респираторных инфекций, опрос о возможных контактах с заболевшими.',
        content5: 'При наличии признаков инфекции (повышенная температура, кашель, насморк) у работника – обязательное отстранение от нахождения на рабочем месте!',
        content6: '3. Организовать при входе мест для обработки рук кожными антисептиками',
        content7: 'Жидкости, лосьоны, гели с содержанием этилового спирта не менее 70% по массе, изопропилового не менее 60% по массе',
        content8: '4. Запретить вход в салон лицам, не связанных с деятельностью заведения.',
        content9: '5. Ограничение контактов между работниками и посетителями:',
        content10: '- обслуживание по предварительной записи с соблюдением интервала между посетителями не менее 20 минут для исключения контакта;',
        content11: '- исключение ожидания обслуживания клиентов внутри салона красоты. При непредвиденном скоплении очереди обеспечить дистанцию между клиентами (1,5 метра) с возможностью ожидания на улице.',
        content12: '- оказание услуг каждому посетителю в отдельном кабинете;',
        content13: '- организация посменной работы сотрудников;',
        content14: '- ограничение перемещения работников во время обеденного перерыва: выход за территорию организации;',
        content15: '- выделение для приема пищи отдельной комнаты, оборудованной раковиной для мытья рук и дозатором для обработки кожным антисептиком;',
        content16: '6. Соблюдение дистанции между работниками и посетителями, в т.ч. путем нанесения разметки 1,5 метра.',
        content17: '7. Обеззараживание воздуха в помещениях с постоянным нахождением работников и посетителей с помощью бактерицидных облучателей-рециркуляторов, разрешенных для применения в присутствии людей. Требуется наличие паспорта на соответствующее оборудование.',
        content18: '8. Соблюдение личной гигиены сотрудниками: частое мытье рук, использование кожных антисептиков (жидкости, лосьоны, гели, с содержанием этилового спирта не менее 70% по массе, изопропилового не менее 60% по массе).',
        content19: '9. Использование работниками средств индивидуальной защиты IV-типа (пижама, медицинский халат, шапочка, маска - со сменой каждые 3 часа) или респиратор фильтрующий, перчатки, носки, тапочки или туфли.',
        content20: '10. Ежедневная (после окончания работы) стирка белья по договору со специализированной организацией или в самом салоне (при наличии условий).',
        content21: '11. Наличие пятидневного запаса средств индивидуальной защиты, дезинфицирующих и моющих средств.',
        content22: '12. Проведение проветривания каждые 2 часа или после каждого посетителя.',
        content23: '13. Проведение влажной уборки помещений и мест общего пользования (туалетные комнаты, комнаты приема пищи и отдыха).',
        content24: 'После завершения обслуживания каждого клиента проведение обработки всех контактных поверхностей (дверные ручки, подлокотники, выключатели и т.д.) с применением дезинфицирующих средств. Проведение дезинфекции рабочего инструмента после каждого клиента. Иметь в наличии подтверждение дезинфицирующих мероприятий, позволяющих оценить объем, качество и своевременность (фото или видео).',
        content25: '14. Организация контроля за применением работниками средств индивидуальной защиты.',
        content26: '15. Организация в течение рабочего дня осмотра работников на признаки респираторных заболеваний.',
        content27: '16. Временное отстранение от работы лиц из групп риска (старше 65 лет, имеющие хронические заболевания, сниженный иммунитет, беременных с обеспечением режима самоизоляции).',
        content28: '17. Недопущение к работе сотрудников без актуальных результатов предварительных и периодических медицинских осмотров.',
    },
    {
        slug: 'article06',
        title: 'Как поддержать команду?',
        author: 'Игорь Климов',
        date: '2022-09-13',
        summary: 'Государственные меры поддержки сотрудников, которыми можно воспользоваться уже сейчас',
        imageUrl: ArticleImg06,
        content1: 'Индустрия красоты как никогда нуждается в поддержке, а сохранение взаимоотношений между мастерами, предпринимателями, гостями является ключевой задачей для всего сообщества. Как вы, предприниматели можете поддержать свою команду в кризисе?',
        content3: 'В качестве психологических мер поддержки, мы предлагаем включить режим максимальной человечности и эмпатии. Сейчас время для коллективных решений: "один за всех и все за одного". Для этого вы можете переключить внимание вашей команды, делиться полезным контентом и лайфхаками, говорить друг другу слова поддержки.',
        content4: 'Если сотрудникам сложно, а вы не обладаете необходимыми навыками для оказания психологической помощи или она нужна вам самому -то обязательно обратитесь по телефонам бесплатной горячей линии в период пандемии.',
        content5: 'После оказания психологической поддержки, вы можете поделиться со своими сотрудниками возможностями, которые предоставляет государство. Физические лица имеют возможность:',
        content6: '1. Взять ипотечные или кредитные каникулы',
        content7: 'Физические лица, чей среднемесячный доход по сравнению с 2019 годом снизился на 30% и более (и он/она может подтвердить это документально) вправе требовать от кредиторов приостановления срока исполнения обязательств на период до 6 месяцев без применения к ним штрафных санкций или взыскания заложенного имущества. При этом вы можете выбрать формат каникул либо по ипотечному кредиту, либо по потребительскому. Для оформления, нужно обратиться в банк, где оформлен кредит.',
        content8: '2. Встать на учет в службу занятости и получить пособие по безработице:',
        content9: 'Пособие в размере 19,500 рублей. Для этого нужны:',
        content10: '1 - Регистрация по месту жительства в Москве',
        content11: '2 - отработали не менее 60 дней в 2020 году',
        content12: '3 - уволены по собственному желанию, в связи с сокращение штата или по соглашению сторон',
        content13: '3. Получить ежемесячное пособие семьям с детьми',
        content14: 'Семьям, имеющим право на материнский капитал, дополнительно полагается выплата по 5.000 рублей на каждого ребенка в возрасте до 3 лет. Выплаты начнутся с апреля (то есть деньги поступят в апреле, мае и июне). Выплаты на детей от 3 до 7 лет начнутся с июня.',
        content15: 'Ежемесячные выплаты на детей от 3 до 7 лет вводятся на постоянной основе вне зависимости от ситуации с распространением коронавируса. На данный момент нет информации о конкретных механизмах, которые позволят осуществлять выплаты. Скорее всего это будет происходить автоматически. Для получения выплаты не потребуется подтверждения уровня доходов и прочих справок или сведений.',
        content16: '4. Продолжить получение социальных пособий и льгот',
        content17: 'Всем получателям социальных льгот и пособий в течение полугода необязательно доказывать, что они до сих пор нуждаются в государственной поддержке: все денежные выплаты и пособия будут продлены автоматически. Эта мера касается получателей нескольких видов пособий: малообеспеченным семьям на детей до трех лет; одиноким родителям на детей; пособие на ребенка до 18 лет нуждающимся; субсидии на оплату коммунальных услуг; ежемесячные "президентские" выплаты на детей; пособия инвалидам.',
        content18: '5. Воспользоваться снижением комиссии за переводы и онлайн-транзакции',
        content19: '1 - Переводы в Системе быстрых платежей до 100 тыс. рублей в месяц должны осуществляться банками без взимания платы;',
        content20: '2 - Переводы на сумму свыше 100 тыс. рублей в месяц — не более 0,5% от суммы перевода, но не более 1500 рублей.',
        content21: '3 - Максимум 1% комиссии на эквайринг с 15 апреля по 30 сентября 2020 года.',
        content22: 'ЗА ПОДДЕРЖКОЙ МОЖНО ОБРАЩАТЬСЯ:',
        content23: 'ПО МОСКВЕ : Работает горячая линия поддержки предпринимателей Единый телефон 8 (495) 620-27-34',
    },
    {
        slug: 'article07',
        author: 'Роман Ковальский',
        date: '2022-06-24',
        title: 'Этический манифест: обращение к бьюти индустрии',
        summary: 'Почему мастерам необходимо отказаться от работы на дому во время режима самоизоляции',
        imageUrl: ArticleImg07,
        content1: 'Дорогие коллеги! Мы просим вас остановить выезд мастеров на дом! Сейчас нам важно объединиться, чтобы остановить риск распространения коронавирусной инфекции!',
        content2: 'Бьюти-сфера серьезно пострадала из-за кризиса, связанного с текущей экономической и социальной ситуацией. Это факт. Неприятный, болезненный факт, от которого мы никуда не денемся. В данном случае мы можем только давать обратную реакцию, и пока большинство из нас осознанно подходят к мерам всеобщей безопасности и уходят на самоизоляцию, остаются салоны и индивидуальные мастера, которые продолжают работать на дому. Мы все терпим колоссальные убытки, но сейчас вопросы финансов должны отойти на второй план.',
        content3: 'Каждый из нас думает: «Как спасти бизнес и свой доход в кризисной ситуации? Бросить все силы на поиски возможностей? Если клиенты готовы платить, мы будем искать возможности оказать им услугу». Эта логика сработала бы в любой кризисной ситуации, но не в той, что происходит сейчас. Сейчас, речь идет о пандемии, о вирусе, который охватил весь мир и распространяется с огромной скоростью. Мы несем ответственность не только за свое здоровье, но и здоровье других людей, которые нам доверяют.',
        content4: 'Поймите, никто из нас не может гарантировать безопасность другого человека. Ни маски, ни перчатки, ни отсутствие симптомов — не гарантия отсутствия заболевания. Вирус и его распространение возможно сдержать только радикальными мерами, и чем дольше мы к ним идем — тем дальше нас отбрасывает к началу. Давайте начнем с себя и просто остановим эту опасную тенденцию. Подумайте о своих мастерах, о своих клиентах, о людях вокруг, которые могут серьезно пострадать или даже погибнуть из-за ваших действий.',
        content5: 'Если здоровье и безопасность недостаточно сильный аргумент, то есть меры, которые могут быть применены уже на законодательном уровне. Стоит ли несколько заработанных тысяч рублей вашей свободы?',
        content6: 'Мы, МЕЖДУНАРОДНОЙ АССОЦИАЦИИ КРАСОТЫ И УХОДА "МАКУ", призываем вас остановиться и переосмыслить все, что происходит в данную минуту. Найти другой ресурс для поддержки своих сотрудников и репутации бренда, потому что он есть.',
        content7: 'Например, сети салонов Laser Pro и «Пальчики» бесплатно предоставляет своим сотрудникам возможность переквалификации, сеть салонов «Моне» выпускает онлайн уроки красоты для гостей по подписке и задействуют мастеров в онлайн формате, сеть салонов «Персона» организовала чат поддержки командного духа и «горячую линию» для тех, у кого есть вопросы по текущей ситуации. Сети «Москвичка» brow & beauty bar и Москвичка Nails сохраняют скидку 30% на все услуги, чтобы ей можно было воспользоваться после карантина.',
        content8: 'Решения есть! Давайте искать их вместе! Присоединяйтесь к МЕЖДУНАРОДНОЙ АССОЦИАЦИИ КРАСОТЫ И УХОДА "МАКУ" (IABC) и выходите из кризиса красиво!',
    },
    {
        slug: 'article08',
        author: 'Игорь Климов',
        date: '2022-03-15',
        title: '30% сотрудников в Москве переводят на удалёнку',
        summary: 'Как это затронет индустрию красоты и чем обернется?',
        imageUrl: ArticleImg08,
        content1: 'В связи с ростом заболеваемости COVID-19 работодателям столицы было рекомендовано перевести на удаленную работу не менее 30 процентов сотрудников.',
        content2: 'С 5 октября работодатели должны предоставить сведения об организации, виде деятельности и фактических адресах, где работают сотрудники. Кроме того, нужно указать, сколько работников перешло на удаленную работу, а сколько продолжает ездить на предприятия.',
        content3: '6 октября 2020 г. вышел новый Указ № 97-УМ О внесении изменений в указы Мэра Москвы от 5 марта 2020 г. № 12-УМ и от 8 июня 2020 г. № 68-УМ',
        content4: 'Рекомендовано усилить контроль за соблюдением мер безопасности на производстве и в офисах.',
        content5: 'Режим удаленной работы продлится по 28 октября включительно. Неполное предоставление сведений повлечет штраф или приостановление деятельности в соответствии со статьей 20.6.1 Кодекса Российской Федерации об административных правонарушениях (п. 2.2 Указа Мэра Москвы № 97-УМ от 06.10.2020).',
        content6: '',
    },
    {
        slug: 'article19',
        author: 'Степан Роговцев',
        date: '2022-01-16',
        title: 'Топ самых высокооплачиваемых мастеров бьюти-сферы в Украине: кто и сколько зарабатывает?',
        // summary: 'Рассмотрим, сколько получают самые высокооплачиваемые мастера бьюти-индустрии в Украине, и кто из них вошел в топ-5 в разных направлениях.',
        imageUrl: ArticleImg19,
        content1: 'Сфера красоты продолжает стремительно развиваться, и многие специалисты в этой индустрии могут похвастаться высокими заработками, особенно те, кто занимается уникальными и востребованными направлениями. В этой статье мы рассмотрим, сколько получают самые высокооплачиваемые мастера бьюти-индустрии в Украине, и узнаем, кто из них вошел в топ-5 лучших мастеров в разных направлениях, включая специалистов по маникюру, косметологии, парикмахерскому искусству, визажу и наращиванию ресниц.',
        content2: 'Топ-5 самых высокооплачиваемых мастеров бьюти-индустрии по направлениям:',
        content3: '1. Косметологи',
        imageUrl2: ArticleImg19_1,
        content4: 'Косметология — это направление, где профессионалы с многолетним опытом и уникальными навыками могут зарабатывать внушительные суммы. Работы высококлассных косметологов включают инъекционные процедуры, лечение кожи и косметические процедуры премиум-класса. Средний доход ведущих специалистов может превышать 1 миллион гривен в год.',
        content5: 'Топ-5 косметологов:',
        textli1: '1. Анна Беспалова – 1 800 000 грн/год',
        textli2: '2. Виктория Зайцева – 1 600 000 грн/год',
        textli3: '3. Ольга Мельникова – 1 500 000 грн/год',
        textli4: '4. Дмитрий Гончаров – 1 400 000 грн/год',
        textli5: '5. Марина Коваленко – 1 300 000 грн/год',
        content6: '2. Парикмахеры и стилисты',
        imageUrl3: ArticleImg19_2,
        content7: 'Профессиональные парикмахеры и стилисты, особенно те, кто работает с известными клиентами или на специализированных мероприятиях, зарабатывают очень высокие гонорары. Услуги премиум-класса включают не только стрижки, но и создание индивидуальных образов, уход за волосами и сложные окрашивания.',
        content8: 'Топ-5 парикмахеров:',
        textli6: '1. Евгений Шаповалов – 2 200 000 грн/год',
        textli7: '2. Елена Лазаренко – 1 900 000 грн/год',
        textli8: '3. Ирина Ковальчук – 1 700 000 грн/год',
        textli9: '4. Сергей Бондаренко – 1 600 000 грн/год',
        textli10: '5. Антон Беляев – 1 500 000 грн/год',
        content9: '3. Мастера маникюра',
        imageUrl4: ArticleImg19_3,
        content10: 'Мастера маникюра, особенно те, кто специализируется на сложных дизайнах или инновационных техниках, также могут рассчитывать на высокие заработки. Особое внимание уделяется работе с VIP-клиентами, что выводит доходы мастеров на новый уровень. Среднегодовая зарплата обычного мастера маникюра составляет около 115 000 гривен, однако топовые специалисты зарабатывают намного больше.',
        content11: 'Топ-5 мастеров маникюра:',
        textli11: '1. Светлана Демидова – 1 450 000 грн/год',
        textli12: '2. Виктория Клименко – 1 302 000 грн/год',
        textli13: '3. Инга Аванесян – 1 265 000 грн/год',
        textli14: '4. Екатерина Сафонова – 1 120 000 грн/год',
        textli15: '5. Юлия Кравченко – 1 107 000 грн/год',
        content12: '4. Визажисты',
        imageUrl5: ArticleImg19_4,
        content13: 'Визажисты, особенно те, кто работает на крупных мероприятиях или сотрудничают с медийными личностями, также входят в число высокооплачиваемых мастеров бьюти-индустрии. Услуги таких мастеров могут стоить дорого, а их календарь расписан на месяцы вперед.',
        content14: 'Топ-5 визажистов:',
        textli16: '1. Ирина Власова – 1 900 000 грн/год',
        textli17: '2. Наталья Гончарова – 1 700 000 грн/год',
        textli18: '3. Алексей Смирнов – 1 600 000 грн/год',
        textli19: '4. Алена Руденко – 1 500 000 грн/год',
        textli20: '5. Мария Полякова – 1 400 000 грн/год',
        content15: '5. Мастера по наращиванию ресниц',
        imageUrl6: ArticleImg19_5,
        content16: 'Мастера по наращиванию ресниц продолжают набирать популярность, а услуги премиум-класса в этом направлении также приносят хорошие доходы. Специалисты с многолетним опытом и уникальной техникой могут зарабатывать сотни тысяч гривен ежегодно.',
        content17: 'Топ-5 мастеров по наращиванию ресниц:',
        textli21: '1. Маргарита Бондаренко – 1 200 000 грн/год',
        textli22: '2. Елена Стаховская – 1 100 000 грн/год',
        textli23: '3. Валерия Новикова – 1 050 000 грн/год',
        textli24: '4. Оксана Дмитриева – 950 000 грн/год',
        textli25: '5. Ирина Голуб – 900 000 грн/год',
        content18: 'Сфера бьюти-индустрии в Украине предлагает профессионалам широкий спектр возможностей для высокого заработка, особенно для тех, кто готов постоянно совершенствовать свои навыки и работать с требовательными клиентами. Топовые специалисты могут зарабатывать миллионы гривен в год, и каждый из них по праву заслуживает своего места в этом списке.',
        content19: 'Секрет успеха мастеров, входящих в топ бьюти-индустрии, кроется не только в их профессионализме, но и в способности идти в ногу с новыми тенденциями и технологиями. Они постоянно обучаются, совершенствуют свои навыки, участвуют в международных конкурсах и конференциях, где демонстрируют свои работы и делятся опытом с коллегами.',
        content20: 'Кроме того, высокие доходы мастеров бьюти-индустрии напрямую связаны с их умением вести бизнес. Многие из них открывают собственные салоны, создают свои продуктовые линии или предлагают уникальные курсы обучения. Эти мастера не только делают бьюти-индустрию доступнее и привлекательнее для клиентов, но и активно способствуют развитию отрасли в целом, повышая уровень сервиса и качества услуг.',
    },
    {
        slug: 'article09',
        author: 'Максим Глинский',
        date: '2021-08-10',
        title: 'Все о цифровых пропусках',
        summary: 'Информация о пропускном режиме в Москве',
        imageUrl: ArticleImg09,
        content1: 'Система цифровых пропусков позволит уменьшить количество людей, выходящих на улицу и пользующихся общественным транспортом. От этого напрямую зависит, как город справится с пандемией коронавируса.',
        content2: 'С 15 апреля для поездок по Москве и Московской области на личном и общественном транспорте потребуются специальные цифровые пропуска. Пропуска будут представлять собой специальный код, состоящий из цифр и букв. Первые четыре знака обозначат дату окончания срока действия пропуска, а остальные 12 знаков позволят идентифицировать его владельца и цель поездки.',
        content3: 'Виды пропусков:',
        content4: '1. Для работы: Срок действия до 30 апреля, для тех, кому необходимо присутствовать на рабочем месте, а также служебных разъездов (курьеров).',
        content5: '2. Для поездок в медицинские учреждения: Количество таких пропусков в неделю не ограничено, нужно название мед учреждения.',
        content6: '3. Разовый пропуск для поездки в личных целях (в магазин, на дачу, на вокзал и т.д.): Такой пропуск выдается на поездку до места назначения и обратно не более двух раз в неделю',
        content7: 'Для передвижения по городу пешком пропуск не требуется.',
        content8: '1. Онлайн - Для этого пользователям нужно авторизоваться на портале, выбрать услугу «Получение цифрового пропуска для передвижения по территории города Москвы» ввести необходимые данные и подать заявку.',
        content9: '2. Позвонив в Единую справочную службу Правительства Москвы: +7 (495) 777-77-77. Оператор контакт-центра запросит необходимую информацию и самостоятельно заполнит данные для цифрового пропуска. После чего продиктует код цифрового пропуска. Этот код необходимо записать и сообщить патрульным службам при проверке.',
        content10: '3. Отправить СМС на номер 7377.',
        content11: 'Проверять цифровой пропуск смогут только уполномоченные лица, осуществляющие контроль передвижения по городу. Вместе с пропуском нужно будет предъявлять документ, удостоверяющий личность, который указан при подаче заявления. Сотрудник проверит цифровой пропуск в присутствии гражданина с помощью специальной программы в планшете.',
    },
    {
        slug: 'article18',
        author: 'Анастасия Пригожина',
        date: '2020-12-24',
        title: 'Престижная награда для специалистов индустрии красоты: итоги 2020 года',
        summary: 'Выдающиеся профессионалы в области красоты получили заслуженные награды',
        imageUrl: ArticleImg18_2,
        imageUrl2: ArticleImg18_1,
        imageUrl3: ArticleImg18_2,
        content1: '20 декабря 2020 года Международная Ассоциация профессионалов индустрии Красоты и Ухода (International Association of Beauty and Care) провела торжественную церемонию, в рамках которой пять лучших специалистов индустрии были удостоены медалей "За заслуги в индустрии красоты". Эта престижная награда стала символом признания и высокой оценки достижений в области красоты.',
        content2: 'Процесс отбора и значение награды',
        content3: 'Церемония награждения была результатом кропотливой работы комитета Ассоциации по Наградам и званиям, который из 154 номинантов выбрал пять выдающихся профессионалов в сфере красоты и велнеса. Медаль "За заслуги в индустрии красоты" присуждается только пяти лучшим специалистам из России, Украины, Азербайджана, Армении, Беларуси, Казахстана, Киргизии, Молдовы, Таджикистана, Туркменистана, Узбекистана и Грузии ежегодно. Это признание высочайшего уровня профессионализма, значительных достижений в теории и практике, а также личного вклада в международное развитие индустрии красоты и велнеса.',
        content4: 'Критерии награждения медалью',
        content5: 'Международная Ассоциация профессионалов индустрии Красоты и Ухода награждает медалью "За заслуги в индустрии красоты" за:',
        textli1:'Внедрение новых и эффективных методов и техник в сфере красоты.',
        textli2:'Высокий уровень профессионализма.',
        textli3:'Признанные заслуги в области теории и практики развития индустрии.',
        textli4:'Значительный личный вклад в международное развитие сферы красоты.',
        textli5:'Международное признание.',
        content6: 'Решение о награждении принимает Совет Правления Ассоциации, в состав которого входят заслуженные и признанные на международном уровне специалисты стран СНГ. Получение этой награды является самой престижной для всех профессионалов индустрии красоты.',
        content7: 'Лауреаты 2020 года',
        content8: 'В 2020 году медалями "За заслуги в индустрии красоты" были удостоены:',
        textli6:'Инга Аванесян - за разработку и внедрение уникальных методов и техник в сфере красоты. Инга, обладатель патента на метод моделирования формы "Стилет" искусственного ногтя, внесла значительный вклад в развитие индустрии ногтевого сервиса.',
        textli7:'Дарья Дружинина - за признанные заслуги в теории и практике развития индустрии красоты и велнеса. Дарья, директор-инструктор Академии Danila Art, активно участвует в обучении и развитии профессионалов.',
        textli8:'Анна Симоненко - за международное признание. Анна, мастер перманентного макияжа, установила высокие стандарты в своей области, получив признание во многих странах.',
        textli9:'Дарья Миронова - за высочайший уровень профессионализма. Дарья, талантливая визажистка, работает с актерами и знаменитостями, обучая молодых специалистов.',
        textli10:'Элеонора Сергеева - за значительный личный вклад в международное развитие сферы красоты. Элеонора, специалист по оформлению бровей и владелица студии микроблейдинга, активно делится своими методиками и техниками.',
        content9: 'Влияние награды и значимость для сообщества',
        content10: 'Получение медали "За заслуги в индустрии красоты" является важным событием не только для лауреатов, но и для всего международного сообщества специалистов по красоте. Эта награда символизирует признание выдающихся достижений, призывая к профессиональному совершенствованию и инновациям. Медаль способствует укреплению международного сообщества, служа вдохновением для молодых специалистов и подтверждением их значимого вклада в развитие индустрии красоты.',
    },
    {
        slug: 'article17',
        author: 'Лидия Проскурина',
        date: '2020-09-01',
        title: 'Прием заявок премии мастеров красоты',
        summary: 'Открыт прием заявок на участие в Международной Премии мастеров красоты',
        imageUrl: ArticleImg16,
        content1: 'Международная Премия Global Beauty Leader приглашает профессионалов индустрии красоты к участию в международном конкурсе, который пройдет с 10 сентября по 25 сентября 2020 года. Премия направлена на поддержку и развитие мастеров красоты, придание нового импульса их творческому и профессиональному совершенствованию.',
        content2: 'Цель премии:',
        content3: 'стимулирование инноваций, креативности и мастерства в индустрии красоты.',
        content4: 'Участники премии:',
        content5: 'профессионалы с опытом работы в индустрии красоты не менее трех лет.',
        content6: 'Формат премии:',
        content7: 'оценка представленных работ по ряду номинаций.',
        content8: 'Номинации:',
        textli1:'Инноватор Года',
        textli2:'Лучший Мастер Эстетической Медицины',
        textli3:'Мастер года в Ногтевой Эстетике',
        textli4:'Прорыв Года',
        textli5:'Мастер года в Перманентном Макияже',
        textli6:'Парикмахер Года',
        textli7:'За Вклад в Развитие Индустрии',
        content9: 'Жюри:',
        content10: 'в состав жюри войдут самые известные профессионалы с международным признанием, обладающие значительным опытом и авторитетом в своих областях.',
        content11: 'Елена Белова Выдающийся ученый в области дерматологии и косметологии, Елена известна своими работами по разработке новых косметических продуктов, которые сочетают в себе последние научные достижения и требования экологической безопасности. Она принимала участие в создании нескольких революционных формул, которые сегодня используются ведущими мировыми брендами. Елена обладает уникальной способностью видеть потенциал в новых технологиях и методиках, что делает её незаменимым членом жюри в номинации "Инноватор Года".',
        content12: 'Ольга Пономарёва Косметолог, массажист, физиотерапевт аппаратной косметологии. Методист-косметолог производителя профессиональной косметики. Фельдшер Территориального центра Медицины катастроф. Преподаватель в медицинском колледже, а также авторских программ по косметологии, эстетических массажей, тейпирования. Стаж работы в косметологии 11 лет.',
        content13: 'Инга Аванесян Выдающийся мастер маникюра с 10-летним стажем, известная своими уникальными техниками и инновационным подходом к дизайну ногтей. Инга внесла значительный вклад в популяризацию экологичных материалов и методов в ногтевом сервисе. Обладатель Патента на полезную модель «Способ моделирования формы «Стилет» искусственного ногтя» Победитель Международного конкурса «Звезды Красоты» 2020. Обладатель Международной премии «Олимп Красоты» 2019.',
        content14: 'Ирина Волкова Ведущий специалист в области молодежных инноваций в косметологии. Ирина известна своей способностью выявлять и поддерживать молодые таланты в индустрии. Она организовала множество стартап-инкубаторов, которые помогли молодым предпринимателям воплотить их идеи в жизнь и внедрить их на международный рынок.',
        content15: 'Дарья Махиня мастер с исключительным талантом и глубокими знаниями в области эстетической коррекции и перманентного макияжа. Она активно внедряет передовые технологии и материалы, обеспечивая высокую степень удовлетворенности клиентов. Победитель Международного конкурса «Звезды Красоты» 2020. Обладатель Международной премии «Олимп Красоты» 2019. Спикер на Международных конференциях.',
        content16: 'Екатерина Дементьева Обучение парикмахерскому искусству в Соединенных Штатах Америки, Победитель и призер региональных и международных чемпионатов, 2015 год - вошла в пятерку лучших мастеров Европы, Чемпионат Европы FESTIVAL INTERNAZIONALE de la COIFFURE e dell ESTETICA (Италия), Организатор семинаров, мастер-классов, курсов повышения квалификации, Сооснователь салона красоты «Честно к себе».',
        content17: 'Николай Васильев Ведущий специалист в интеграции устойчивых технологий в косметическую индустрию. Николай активно участвует в разработке стандартов экологичного производства и является консультантом для крупных международных организаций по вопросам устойчивости. Его вклад в развитие отрасли и продвижение экологически чистых технологий неоценим.',
        content18: 'Жюри премии формируется из самых выдающихся профессионалов в индустрии красоты, каждый из которых прошел тщательный отбор для участия в этом престижном мероприятии. Возможность быть судьей на конкурсе считается высоким признанием профессиональных достижений и авторитета в мировом масштабе.',
        content19: 'Члены жюри избираются на основе их вклада в развитие отрасли, инновационного подхода, профессиональных навыков и международного признания. Это лидеры мнений, чьи решения формируют будущее всей индустрии красоты. В их обязанности входит не только оценка работ премии, но и внесение предложений по совершенствованию стандартов качества и инноваций в сфере красоты.',
        content20: 'Присутствие в жюри таких знаковых фигур подчеркивает стремление организаторов премии к высочайшему уровню профессионализма и справедливости. Быть судьей на таком уровне — значит вносить вклад в формирование нового поколения талантов и поддерживать высокие стандарты индустрии на международной арене.',
        content21: 'Особенности проведения премии:',
        textli8:'Премия проводится офлайн и онлайн, что позволяет участникам из любой точки мира принять в нем участие.',
        textli9:'Прозрачная система оценки работ, исключающая предвзятость и обеспечивающая анонимность участников.',
        textli10:'Использование современных технологий для обеспечения интерактивности и эффективного взаимодействия участников и жюри.',
        content22: 'Дополнительные мероприятия:',
        textli11:'Мастер-классы и вебинары от ведущих экспертов.',
        textli12:'Дискуссионные панели и круглые столы.',
        textli13:'Сессии обратной связи от жюри.',
        textli14:'Нетворкинговые сессии.',
        content23: 'Процесс подачи заявок:',
        content24: 'Для участия необходимо зарегистрироваться через официальный сайт премии до 10 сентября 2020 года. Участники должны представить работы, соответствующие требованиям номинаций.',
        content25: 'Награды:',
        content26: 'Победители получат денежные призы, ценные подарки от спонсоров, возможности для сотрудничества и повышения своего профессионального имиджа. Подведение итогов конкурса состоится 1 октября 2020 года',
        content27: 'Международная премия мастеров красоты предоставляет уникальную платформу для демонстрации мастерства, обмена опытом и творческого роста, способствуя развитию красоты на международном уровне.',
        content28: 'Организаторы премии стремятся к инновациям и профессиональному общению в индустрии красоты, поддерживая развитие и вдохновение участников.',
    },
    {
        slug: 'article16',
        author: 'Иванна Седальская',
        date: '2020-10-06',
        title: 'Завершение Международной премии Global Beauty Leader 2020',
        summary: 'Подведены итоги выдающегося события в мире красоты',
        imageUrl: ArticleImg17,
        content1: '1 октября были подведены итоги выдающегося события в мире красоты — Международной премии, организованной Международной Ассоциацией профессионалов индустрии Красоты и Ухода (International Association of Beauty and Care), проходившего с 10 сентября по 25 сентября 2020 года. ',
        content2: 'Этот год стал знаменательным, собрав более 120 участников в каждой категории из множества стран, включая Россию, Украину, Азербайджан, Беларусь, Молдову, Армению и другие, подчеркивая масштаб и значимость конкурса.',
        content3: 'Премия проводилась в онлайн и офлайн формате, что позволило участникам со всего мира демонстрировать своё мастерство без географических ограничений. В этом году победителей определяло звездное жюри, состоящее из мировых лидеров индустрии красоты, признанных за их инновационный подход и вклад в развитие отрасли:',
        content4: 'Елена Белова Выдающийся ученый в области дерматологии и косметологии, Елена известна своими работами по разработке новых косметических продуктов, которые сочетают в себе последние научные достижения и требования экологической безопасности. Она принимала участие в создании нескольких революционных формул, которые сегодня используются ведущими мировыми брендами. Елена обладает уникальной способностью видеть потенциал в новых технологиях и методиках, что делает её незаменимым членом жюри в номинации "Инноватор Года".',
        content5: 'Ольга Пономарёва Косметолог, массажист, физиотерапевт аппаратной косметологии. Методист-косметолог производителя профессиональной косметики. Фельдшер Территориального центра Медицины катастроф. Преподаватель в медицинском колледже, а также авторских программ по косметологии, эстетических массажей, тейпирования. Стаж работы в косметологии 11 лет.',
        content6: 'Инга Аванесян Выдающийся мастер маникюра с 10-летним стажем, известная своими уникальными техниками и инновационным подходом к дизайну ногтей. Инга внесла значительный вклад в популяризацию экологичных материалов и методов в ногтевом сервисе. Обладатель Патента на полезную модель «Способ моделирования формы «Стилет» искусственного ногтя» Победитель Международного конкурса «Звезды Красоты» 2020. Обладатель Международной премии «Олимп Красоты» 2019.',
        content7: 'Ирина Волкова Ведущий специалист в области молодежных инноваций в косметологии. Ирина известна своей способностью выявлять и поддерживать молодые таланты в индустрии. Она организовала множество стартап-инкубаторов, которые помогли молодым предпринимателям воплотить их идеи в жизнь и внедрить их на международный рынок.',
        content8: 'Дарья Махиня мастер с исключительным талантом и глубокими знаниями в области эстетической коррекции и перманентного макияжа. Она активно внедряет передовые технологии и материалы, обеспечивая высокую степень удовлетворенности клиентов. Победитель Международного конкурса «Звезды Красоты» 2020. Обладатель Международной премии «Олимп Красоты» 2019. Спикер на Международных конференциях.',
        content9: 'Екатерина Дементьева Обучение парикмахерскому искусству в Соединенных Штатах Америки, Победитель и призер региональных и международных чемпионатов, 2015 год - вошла в пятерку лучших мастеров Европы, Чемпионат Европы FESTIVAL INTERNAZIONALE de la COIFFURE e dell ESTETICA (Италия), Организатор семинаров, мастер-классов, курсов повышения квалификации, Сооснователь салона красоты «Честно к себе».',
        content10: 'Николай Васильев Ведущий специалист в интеграции устойчивых технологий в косметическую индустрию. Николай активно участвует в разработке стандартов экологичного производства и является консультантом для крупных международных организаций по вопросам устойчивости. Его вклад в развитие отрасли и продвижение экологически чистых технологий неоценим.',
        content11: 'Список победителей отражает не только высокий уровень их профессионализма, но и культурное разнообразие, присущее конкурсу:',
        content12: 'Инноватор Года',
        textli1:'Антон Иванов (Россия) - за разработку умных устройств для анализа состояния кожи.',
        textli2:'Екатерина Васильева (Россия) - за внедрение персонализированных программ ухода за кожей.',
        content13: 'Лучший Мастер Эстетической Медицины',
        textli3:'Доктор Анастасия Сергеева (Россия) - за разработку новой техники минимально инвазивной липоскульптуры.',
        textli4:'Доктор Илья Коваленко (Украина) - за значительные достижения в области антивозрастной терапии.',
        content14: 'Мастер года в Ногтевой Эстетике',
        textli5:'Светлана Иванова (Россия) - за разработку инновационных техник дизайна ногтей, включая использование экологически чистых материалов.',
        textli6:'Ольга Павлова (Казахстан) - за мастерство в создании уникальных ногтевых артов, с использованием авторских росписей.',
        content15: 'Прорыв Года',
        textli7:'Михаил Андреев (Россия) - за создание нового типа органической косметики.',
        textli8:'Яна Кирсанова (Украина) - за исследования в области биодеградируемых материалов для косметики.',
        content16: 'Мастер года в Перманентном Макияже',
        textli9:'Александра Иванова (Россия) - за мастерство в техниках микроблейдинга и микропигментации.',
        textli10:'Елена Смирнова (Беларусь) - за инновационный подход в перманентном макияже губ.',
        content17: 'Парикмахер Года',
        textli11:'Дмитрий Новиков (Россия) - за создание уникальных причесок и разработку новых стайлинговых продуктов.',
        textli12:'Ольга Петрова (Беларусь) - за вклад в обучение и развитие молодых талантов в парикмахерском искусстве.',
        content18: 'За Вклад в Развитие Индустрии',
        textli13:'Наталья Королева (Россия) - за долгосрочное влияние на развитие косметической индустрии.',
        textli14:'Андрей Жуков (Беларусь) - за создание образовательной платформы для специалистов в области красоты.',
        content19: 'Международная премия Global Beauty Leader не только раскрывает новые таланты и предлагает бесценный опыт, но и укрепляет сетевое сообщество профессионалов. Она продвигает культурный обмен и профессиональное развитие, делая вклад в глобальное развитие индустрии красоты. Надежды и стремления участников, их умения и достижения получили заслуженное внимание и признание на международном уровне благодаря этому масштабному конкурсу.',
        content20: 'Поздравляем победителей и благодарим всех участвующих за их вклад в прославление искусства красоты.',
    },
    {
        slug: 'article10',
        author: 'Тимофей Стрелков',
        date: '2020-03-05',
        title: 'Чек-лист для получения субсидии',
        summary: 'Детально рассмотрим одну из мер: получение субсидии. Срок подачи заявок с 6 марта по 30 апреля 2020 года',
        imageUrl: ArticleImg10,
        content1: 'Субсидии предоставляются только на проценты по кредитам и закупку оборудования, а не в целях возмещения затрат или недополученных доходов!',
        content2: 'Мы уже писали про возможные меры поддержки малого и среднего бизнеса от Правительства Москвы. Давайте более детально рассмотрим одну из мер: получение субсидии. Срок подачи заявок с 6 марта по 30 апреля 2020 года.',
        content3: 'Требования к вашему бизнесу для рассмотрения заявки:',
        content4: '1. Субсидии предоставляются малому и среднему бизнесу по одному или нескольким договорам на получение субсидии.',
        content5: '2. Ваше предприятие должно быть зарегистрировано минимум за 18 месяцев до подачи заявки.',
        content6: '3. Вы должны быть зарегистрированы в качестве налогоплательщика в Москве',
        content7: '4. Существующий кредитный договор с банком',
        content8: '5. Отсутствие действующего договора о предоставлении бюджетных средств от государства',
        content9: '6. Отсутствие нарушений своих обязательств (штрафы, задолженности по уплате налогов и др.)',
        content10: '7. Заявки принимаются только от российских компаний; допускается участие в их капитале (до 50%) иностранных лиц, зарегистрированных в государствах или на территориях, включенных в Перечни Минфина Р Ф как льготные налоговые территории или территории, не раскрывающие информацию',
        content11: 'Шаги для получения субсидии:',
        content12: 'Если ваш бизнес соответствуете всем требованиям, то вы можете:',
        content13: '1. Подать заявку на получение субсидии и предоставить перечень документов',
        content14: '2. Срок начала и окончания приема заявок устанавливается Департаментом, обновления можно посмотреть на сайте, в течение 10 дней вашу заявку должны обработать. В случае отказа и списка замечаний к документам, вы можете повторно отправить заявку.',
        content15: '3. После правовой и финансовой экспертизы, выездной проверки создается отраслевая комиссия, которая оценивает заявки и присваивает им порядковый номер (чем больше итоговая оценка, тем меньший порядковый номер, при совпадении оценки, меньшую получит тот, кто раньше подал заявку). «10 рабочих дней',
        content16: '4. По итогам рассмотрения заявок, протокол размещается на официальном сайте, Департамент оформляет правовой акт и уведомляет претендента о решении («14 рабочих дней)',
        content17: '5. Оформление и заключение договора на предоставление субсидии («15 рабочих дней)',
        content18: '6. Компенсация перечисляется на счет получателя. При этом департамент следит за выполнением условий, целей и порядка предоставления субсидии',
        content19: 'ВНИМАНИЕ! Субсидии не предоставляются в целях возмещения затрат или недополученных доходов! Только на проценты по кредитам и закупку оборудования',
        content20: 'Субсидия предоставляется в размере затрат на уплату процентов по кредитам с 1 января 2019 года (предшествующий год подачи заявки) до окончания срока действия кредита (но не более 3 финансовых лет). Суммарный размер субсидии не должен превышать одного миллиона рублей.',
    },
    {
        slug: 'article15',
        author: 'Станислав Иванищук',
        date: '2020-02-05',
        title: 'Завершение конкурса 2020 год',
        summary: 'Завершение Международного Конкурса бьюти мастеров “Звезды Красоты” ',
        imageUrl: ArticleImg15,
        imageUrl2: ArticleImg15_2,
        imageUrl3: ArticleImg15_3,
        content1: '1 февраля были подведены итоги мирового события сферы красоты — Международного Конкурса бьюти мастеров “Звезды Красоты”. Мероприятие собрало участников из множества стран, включая Россию, Украину, Азербайджан, Беларусь, Молдову, Армению, Грузию, Казахстан, Узбекистан и другие, что по праву дает этому конкурсу мировое значение и влияние на сферу красоты. В этом году Конкурс “Звезды Красоты” собрал более чем 400 талантливых участников из таких категорий индустрии красоты.',
        content2: 'Как и в предыдущие годы, номинации для участников охватили самые разнообразные и востребованные категории в мире красоты и велнеса. В этом году конкурс включал номинации, отражающие последние тренды и инновации в индустрии. Участники могли продемонстрировать свои навыки и творческий подход в различных областях, от парикмахерского искусства до косметологии и ногтевого сервиса, вот список всех номинаций:',
        textli1:'Маникюр, моделирование, дизайн, педикюр и подология',
        textli2:'Перманентный макияж, визаж, а также Lash и Brow направление',
        textli3:'Парикмахерское искусство',
        textli4:'Инновации в косметологии',
        textli5:'Массаж и телесные терапии',
        textli6:'Мастерство в области стиля и имиджа',
        textli7:'Фотография и видеография в индустрии красоты',
        content3: 'Конкурс объединил самых одаренных и профессионально подготовленных участников, каждый из которых продемонстрировал превосходные навыки в своей сфере. Участники продемонстрировали не только высокую техническую компетентность, но и творческий подход к выполнению заданий. Жюри высоко оценило их выдающиеся результаты, что подчеркивает их глубокий профессионализм и тщательную подготовку. Каждый конкурсант внес свой неповторимый вклад, делая соревнование динамичным и увлекательным. Победителями конкурса стали:',
        content4: 'Маникюр, моделирование, дизайн, педикюр и подология',
        content5: '1 место - Инга Аванесян (Украина)',
        content6: '2 место - Ирина Свиридова (Россия)',
        content7: '3 место - Иванна Лукина (Белорусь)',
        content8: 'Перманентный макияж, визаж, а также Lash и Brow направление',
        content9: '1 место - Дарья Махиня (Украина)',
        content10: '2 место - Светлана Морозова (Россия)',
        content11: '3 место - Наталья Петрова (Молдова)',
        content12: 'Парикмахерское искусство',
        content13: '1 место - Лида Курбанова (Казахстан)',
        content14: '2 место - София Руденко (Украина)',
        content15: '3 место - Алиса Воронцова (Россия)',
        content16: 'Инновации в косметологии',
        content17: '1 место - Карина Абдуллина (Казахстан)',
        content18: '2 место - Вера Сидорова (Россия)',
        content19: '3 место - Ирина Белоусова (Украина)',
        content20: 'Массаж и телесные терапии',
        content21: '1 место - Лариса Кузнецова (Казахстан)',
        content22: '2 место - Василий Теркин (Россия)',
        content23: '3 место - Сергей Петров (Молдова)',
        content24: 'Мастерство в области стиля и имиджа',
        content25: '1 место - Алина Садыкова (Казахстан)',
        content26: '2 место - Мария Полищук (Украина)',
        content27: '3 место - Вера Захарова (Россия)',
        content28: 'Фотография и видеография в индустрии красоты',
        content29: '1 место - Дмитрий Сергеев (Беларусь)',
        content30: '2 место - Иван Чернов (Россия)',
        content31: '3 место - Сергей Левченко (Украина)',
        content32: 'Оценка конкурса проводилась высококвалифицированными специалистами в своих областях, имеющие многочисленные достижения и обладающие выдающимся мастерством. Члены жюри обладают значительным опытом и неоднократно демонстрировали свои таланты на международной арене. Их профессионализм и глубокие знания позволили объективно оценить работы участников и выбрать лучших. Экспертная комиссия установила высокий стандарт судейства, обеспечивая заслуженную оценку каждому участнику. Среди жюри 2020 года были такие выдающиеся специалисты как:',
        content33: 'Инна Лантрова (Россия) Директор компании «KorNail», Председатель и главный тренер по компетенции ногтевой сервис "Абилимпикс", Тренер Национальной сборной "Абилимпикс", Тренер сборной команды Expert Centre, Победитель и призёр российских и международных конкурсов, Организатор чемпионатов Nail Bridge и Nail Battle',
        content34: 'Виктория Марчук (Россия) Визажист, мастер по прическам Международного класса, Многократный чемпион, призер российских и международных соревнований по макияжу, боди-арту, Визажист недели моды в Париже, Визажист, участник и организатор международных проектов, Официальный визажист, член жюри конкурсов Мисс, Миссис Псков, Владелица собственной студии макияжа',
        content35: 'Владимир Сарбашев (Россия) Маэстро колористики, Автор AirTouch, одной из самых востребованных техник окрашивания в мире, Владелец бьюти-ателье Subrosa и академии парикмахерского искусства VS Academy, Преподаватель, на мастер-классы которого собираются полные залы, Прошел путь от ассистента до колориста международного уровня, Более 15 лет работал мастером-универсалом и техническим директором Toni&Guy',
        content36: 'Наталья Богомякова (Россия) Эксперт рынка косметологии, Врач-косметолог, сертифицированный тренер Lady Land, Автор и организатор Чемпионатов, Обучила более 350 успешных тренеров в косметологии, Опыт работы более 20 лет',
        content37: 'Евгений Сидякин (Украина) Основатель и руководитель «Международной школы Миопластики", Lifestyle-эксперт, массажист, косметолог, спа-терапевт, health-коуч, Специалист по реабилитации и физическому воспитанию, Специалист по созданию и оптимизации технологических процессов на предприятиях спа, Автор «Миопластики лица и тела»',
        content38: 'Радмила Оброщикова (Россия) Российский дизайнер, Председатель комитета индустрии моды, Вице-президент Ассоциации Текстильщиков России, Представитель Национальной Федерации Моды, Опыт работы более 10 лет',
        content39: 'Валентина Осинцева (Россия) 13 лет коммерчески успешный фотограф, Создатель масштабного фотокурса для родителей и семейных фотографов «Дети в фокусе», Развивает популярность семейной фотографии в России, Проводит мастер-классы и учит не только фотографировать, но и зарабатывать на фотографии, Ей доверяют фотографировать своих детей известные блогеры',
        content40: 'Международный Конкурс бьюти мастеров "Звезды Красоты" ежегодно устраивает яркое и впечатляющее шоу для профессионалов индустрии красоты, заслуживших мировое признание. В рамках мероприятия участники не только демонстрируют свои уникальные таланты, но и активно обмениваются международным опытом и новаторскими идеями. Этот конкурс служит не просто ареной для проявления профессионального мастерства, но и важной платформой для укрепления международных связей и сетевого взаимодействия среди участников.',
        content41: 'Высокий уровень каждого конкурсанта и членов жюри подчеркивает значимость события, способствуя развитию и популяризации индустрии красоты на мировом уровне. Конкурс помогает профессионалам не только продемонстрировать свои способности, но и вдохновить других на внедрение инноваций и повышение стандартов в своих областях. Поздравляем победителей и выражаем искреннюю благодарность всем участникам за их вклад в возвышение искусства красоты.',
    },
    {
        slug: 'article14',
        author: 'Дарина Морозова',
        date: '2019-12-20',
        title: 'Открыт прием заявок 2020 год',
        summary: 'Открыт прием заявок на участие в Международном конкурсе бьюти мастеров “Звезды Красоты” - 2020',
        imageUrl: ArticleImg14,
        content1: 'Ежегодный Международный Конкурс бьюти мастеров "Звезды Красоты" приглашает профессионалов индустрии красоты принять участие в одном из самых престижных и влиятельных международных конкурсов. Прием заявок с 1 по 31 января. Конкурс направлен на поддержку и развитие талантливых мастеров красоты на глобальной арене, предоставляя им уникальную возможность для творческого и профессионального роста.',
        content2: 'Конкурс представляет собой значимую и влиятельную платформу, где собираются лучшие из лучших в индустрии красоты со всего мира. Участники не только демонстрируют свои уникальные навыки и мастерство, но и активно обмениваются международным опытом и инновационными идеями. Благодаря высокому уровню каждого конкурсанта и членов жюри, конкурс способствует развитию и популяризации бьюти-индустрии на мировом уровне.',
        content3: 'Международный Конкурс бьюти мастеров "Звезды Красоты" — это не просто сцена для проявления профессионализма, но и важная платформа для установления международных связей и профессиональных взаимодействий. Это мероприятие помогает участникам не только показать свои таланты, но и вдохновить других на внедрение инноваций и повышение стандартов в их областях. Мы поздравляем всех победителей и выражаем искреннюю благодарность всем участникам за их вклад в возвышение искусства красоты.',
        content4: 'Учитывая крайне негативную экономическую ситуацию, мы обратились к Правительству Москвы с просьбой оказать поддержку предприятиям, индустрии красоты, а именно',
        content5: 'Номинации Международного Конкурса бьюти мастеров "Звезды Красоты"',
        content6: 'Маникюр, моделирование, дизайн, педикюр и подология',
        content7: 'Эта номинация объединяет специалистов, которые владеют искусством ухода за ногтями и стопами, демонстрируя высокий уровень мастерства в маникюре, моделировании ногтей, художественном дизайне, а также в педикюре и подологии.',
        content8: 'Перманентный макияж, визаж, Lash и Brow направление',
        content9: 'Здесь участники соревнуются в различных техниках макияжа, включая перманентный макияж, классический и креативный визаж, а также в направлениях, связанных с ресницами и бровями, показывая свои навыки в создании выразительных образов.',
        content10: 'Парикмахерское искусство',
        content11: 'Эта номинация посвящена мастерам парикмахерского искусства, которые демонстрируют свои таланты в стрижке, укладке, окрашивании и создании уникальных причесок. Участники показывают инновационные техники и творческий подход к созданию стиля.',
        content12: 'Инновации в косметологии',
        content13: 'Специалисты в области косметологии соревнуются, представляя новейшие методы и технологии ухода за кожей, омоложения и эстетических процедур. Эта номинация подчеркивает важность инноваций и научных достижений в индустрии красоты.',
        content14: 'Массаж и телесные терапии',
        content15: 'Участники этой номинации демонстрируют свое мастерство в различных техниках массажа и телесных терапий, способствующих улучшению здоровья и общего самочувствия. Особое внимание уделяется инновационным методикам и интегративному подходу к телесным практикам.',
        content16: 'Мастерство в области стиля и имиджа',
        content17: 'Здесь соревнуются стилисты и имиджмейкеры, которые показывают свои умения в создании гармоничных и запоминающихся образов. Включает работу с одеждой, аксессуарами и общим стилем, подчеркивая индивидуальность каждого клиента.',
        content18: 'Фотография и видеография в индустрии красоты',
        content19: 'Эта номинация открыта для фотографов и видеографов, которые специализируются на создании визуального контента в сфере красоты. Участники демонстрируют свое мастерство в съемке и редактировании изображений и видео, которые подчеркивают эстетику и инновации в индустрии красоты.',
        content20: 'Участники Международного Конкурса "Звезды Красоты” представляют собой элиту индустрии красоты, лидеров международного уровня, чьи имена известны по всему миру. Каждый участник конкурса — это высококвалифицированный специалист, обладающий многолетним опытом работы в своей области. Согласно требованиям конкурса, все участники должны иметь не менее трех лет профессионального опыта, что гарантирует высокий уровень мастерства и исключительные навыки.',
        content21: 'Этот конкурс привлекает только лучших из лучших, тех, кто уже завоевал признание на национальных и международных площадках. Их участие в конкурсе — это не только возможность продемонстрировать свои таланты, но и шанс обменяться передовыми идеями и методиками с коллегами из разных стран. Участники приезжают на конкурс с целью не только победить, но и обогатить свою профессиональную практику, вдохновившись работами своих коллег.',
        content22: 'Конкурс создает уникальные условия для раскрытия потенциала каждого участника. Важно отметить, что участники проходят строгий отбор, что делает участие в этом конкурсе особой честью. Каждый конкурсант приносит свою уникальную технику и творческий подход, что делает соревнование насыщенным и многообразным.',
        content23: 'Благодаря международному масштабу и высокому уровню профессионализма участников, конкурс "Звезды Красоты: Таланты Beauty Сферы" не только способствует личному и профессиональному росту каждого мастера, но и продвигает индустрию красоты в целом, устанавливая новые стандарты и тенденции.',
        content24: 'Чтобы принять участие в конкурсе, необходимо зарегистрироваться на официальном сайте мероприятия с 1 по 31 января 2020 года. Все претенденты должны предоставить свои работы, которые соответствуют требованиям выбранных номинаций.',
        content25: 'Победители будут награждены денежными призами, ценными подарками от спонсоров, сертификатами, а также получат уникальные возможности для сотрудничества и повышения своего профессионального статуса. Итоги конкурса будут подведены 1 февраля 2020 года.',
        content26: 'Международный конкурс мастеров "Звезды Красоты" предоставляет уникальную платформу для демонстрации мастерства, обмена опытом и творческого роста, способствуя развитию индустрии красоты на международном уровне. Организаторы стремятся внедрять инновации и стимулировать профессиональное взаимодействие в сфере красоты, поддерживая развитие и вдохновение участников.',
    },
    {
        slug: 'article13',
        author: 'Снежана Добровольская',
        date: '2019-12-14',
        title: 'Итоги премии 2019 год',
        summary: 'Подведены итоги Международной премии для профессионалов в сфере красоты и ухода “Олимп Красоты”',
        imageUrl: ArticleImg13,
        imageUrl2: ArticleImg13_2,
        imageUrl3: ArticleImg13_3,
        content1: 'Завершилась Международная премия "Олимп Красоты", собравшая более 450 профессионалов из разных уголков мира, от России до Узбекистана. Мероприятие, прошедшее с 1 по 10 декабря 2019 года, вновь подтвердило свой статус одного из самых значимых и масштабных событий в индустрии красоты.',
        content2: 'В этом году победителями стали специалисты, продемонстрировавшие самые инновационные и впечатляющие достижения в своей области. Их работы не только выделялись креативным подходом, но и воплощали новейшие технологии и методы. Эти достижения были высоко оценены жюри, что подчеркнуло их исключительный профессионализм. Победители продемонстрировали, что именно они определяют будущее индустрии красоты своими передовыми идеями и техниками.',
        content3: 'В список победителей попали такие выдающиеся специалисты:',
        content4: 'Категория «Мастер ногтевого сервиса»',
        content5: 'НОМИНАЦИИ:',
        textli1:'«Инновации в процедурах» - Инга Аванесян (Украина)',
        textli2:'«Прорыв года» - Маргарита Чернышова (Беларусь)',
        content6: 'Категория «Парикмахер»',
        content7: 'НОМИНАЦИИ:',
        textli3:'«Инновации в процедурах» - Анастасия Михалюк (Россия)',
        textli4:'«Прорыв года» - Катерина Радевич (Украина)',
        content8: 'Категория «Косметолог»',
        content9: 'НОМИНАЦИИ:',
        textli5:'«Инновации в процедурах» - Светлана Андреева (Молдова)',
        textli6:'«Прорыв года» - Ольга Лунина (Россия)',
        content10: 'Категория «Мастер перманентного макияжа»',
        content11: 'НОМИНАЦИИ:',
        textli7:'«Инновации в процедурах» - Анна Игнатенко (Россия)',
        textli8:'«Прорыв года» - Дарья Махиня (Украина)',
        content12: 'Категория «Тату мастер»',
        content13: 'НОМИНАЦИИ:',
        textli9:'«Инновации в процедурах» - Елена Иванова (Россия)',
        textli10:'«Прорыв года» - Мария Потапова (Беларусь)',
        content14: 'Жюри Международной премии "Олимп Красоты" применило тщательно продуманную и объективную систему оценки, чтобы определить победителей. Вот основные аспекты, по которым проводилось судейство:',
        content15: 'Критерии оценки:',
        textli11: 'Техника исполнения: Жюри уделяло особое внимание качеству выполнения работ, включая точность и аккуратность.',
        textli12: 'Креативность и инновации: Участники должны были продемонстрировать оригинальные идеи и использование новых технологий в своих работах.',
        textli13: 'Эстетика и художественное выражение: Работы оценивались на предмет общей эстетической привлекательности и художественного мастерства.',
        textli14: 'Соответствие теме: Учитывалось, насколько работы соответствовали заявленной теме конкурса.',
        content16: 'Процедура оценки:',
        textli15: 'Анонимное судейство: Для обеспечения объективности все работы оценивались анонимно, без указания имен участников.',
        textli16: 'Многоуровневый подход: Оценка проводилась в несколько этапов, начиная с предварительного отбора и заканчивая финальной оценкой.',
        textli17: 'Коллегиальное обсуждение: После индивидуальной оценки жюри проводило коллективное обсуждение, чтобы прийти к консенсусу относительно лучших работ.',
        content17: 'Состав жюри:',
        textli18: 'Профессионализм и авторитет: В состав жюри вошли признанные международные эксперты с обширным опытом и высоким авторитетом в индустрии красоты:',
        content18: 'Вячеслав Дюденко (Украина) Профессор по научно-прикладному направлению "Культура и искусство в области высоких технологий моделирования имидж-дизайна"; ',
        content19: 'Виктория Щербина (Испания) Учредитель школы и салона красоты Lashmaker y Nails beauty "Victoria Art Beauty";',
        content20: 'Анна Надрова (Беларусь) Двукратный призер II Всероссийского конкурса по эстетической косметологии и массажу Ethics; ',
        content21: 'Наталья Егорова (Россия) Автор и руководитель проекта "Реконструкция кожи воздействием плазменной дуги в сочетании с сухой иглой";',
        content22: 'Максим Кислицын (Россия) Участник и судья многих тату-фестивалей по всему миру',
        textli19: 'Разнообразие экспертиз: Жюри было составлено таким образом, чтобы охватить все основные направления и категории конкурса, включая парикмахерское искусство, макияж, ногтевой сервис и косметологию.',
        content23: 'Обратная связь:',
        textli20: 'Комментарии и рекомендации: Каждый участник получил подробную обратную связь от членов жюри, включая конструктивные замечания и рекомендации для дальнейшего профессионального роста.',
        textli21: 'Публичное обсуждение: В ходе мероприятия были организованы сессии обратной связи, где жюри делилось своими впечатлениями и обсуждало работы участников в открытом формате.',
        content24: 'Благодаря такой всесторонней и справедливой системе оценки, Международная премия профессионалов индустрии красоты "Олимп Красоты" не только выявляет лучших профессионалов в индустрии, но и способствует их дальнейшему развитию и совершенствованию.',
        content25: 'Международная Премия "Олимп Красоты: Лидеры Индустрии" представляет собой уникальную платформу для демонстрации профессионального мастерства и важное место для обмена опытом и инновационными идеями. Организаторы создали такие условия, в которых каждый участник мог не только показать свои навыки, но и расширить свои профессиональные горизонты благодаря мастер-классам и вебинарам, проведенным в рамках мероприятия.',
        content26: 'Победители премии были отмечены не только дипломами и призами от спонсоров, но и получили международное признание, что является значимым вкладом в их профессиональное развитие. Мы выражаем нашу искреннюю благодарность всем участникам и поздравляем победителей с их творческими успехами и профессиональным ростом. Этот конкурс не только укрепил международные связи между мастерами красоты, но и продемонстрировал высокий уровень мастерства участников, способствуя развитию всей индустрии.',

    },
    {
        slug: 'article11',
        author: 'Тимофей Стрелков',
        date: '2019-11-12',
        title: 'КОРОНАВИРУС: меры поддержки',
        summary: 'Объединение лидеров индустрии в рамках борьбы с коронавирусом',
        imageUrl: ArticleImg11,
        content1: 'В рамках поддержки индустрии красоты, команда Ассоциации подготовила письмо для Правительства РФ с просьбой рассмотрения поддерживающих мер в рамках текущей экономической и социальной ситуации.',
        content2: 'В связи со сложившейся ситуацией распространения коронавирусной инфекции кто-то в большей, кто-то в меньшей степени уже столкнулся с негативными последствиями для бизнеса.',
        content3: 'Сфера бьюти — одна из первых входит в кризисное состояние: обороты катастрофически падают, прогноз на предстоящий месяц не обнадеживает. Ситуация в салонах красоты и всем бизнесе бьюти услуг меняется молниеносно - не просто каждый день, она меняется каждый час. В этой ситуации мы адаптируемся, ищем решения, как работать в условиях постоянного сокращения клиентов и, соответственно, оборотов при текущих наших обязательствах перед нашими сотрудниками, перед нашими партнерами и перед государством.',
        content4: 'Учитывая крайне негативную экономическую ситуацию, мы обратились к Правительству Москвы с просьбой оказать поддержку предприятиям, индустрии красоты, а именно',
        content5: '1. Включить салоны индустрии красоты в список индустрий (сфера услуг), по которым уже приняты меры государственной поддержки',
        content6: '2. Рассмотреть возможности:',
        content7: '○ Предоставления налоговых каникул;',
        content8: '○ Отсрочки выплаты взносов на пенсионное и обязательное медицинское страхование;',
        content9: '○ Освобождения от выплаты НДФЛ;',
        content10: '○ Выплаты пособий (больничные, декретные и т. п.) из бюджета ФСС непосредственно работникам;',
        content11: '○ Компенсационной финансовой поддержки для урегулирования арендных отношений или правовой формы — признание ситуации форс-мажором для нашей индустрии для урегулирования взаимоотношений с коммерческой арендой.',
        content12: '○ Предоставления беспроцентных кредитов для покрытия кассового разрыва и для обеспечения выплат сотрудникам в условиях вынужденного простоя.',
        content13: '3. Признать ситуацию форс-мажором для индустрии красоты — для возможности оформления простоя по причинам, не зависящим от работодателя и работника, согласно ст. 157 ТК.',
    },
    {
        slug: 'article12',
        author: 'Елена Роговцева',
        date: '2019-09-20',
        title: 'Открыт прием заявок 2019 год',
        summary: 'Открыт прием заявок на участие в Международной премии для профессионалов в сфере красоты и ухода “Олимп Красоты”',
        imageUrl: ArticleImg12,
        imageUrl2: ArticleImg12_2,
        content1: 'Международная премия для профессионалов в сфере красоты и ухода "Олимп Красоты" приглашает профессионалов международного уровня индустрии красоты принять участие в престижной международной премии.',
        content2: 'Прием заявок осуществляется с 1 октября по 1 декабря 2019 года на официальном сайте Премии: https://beauty-olymp.com.',
        content3: 'Премия направлена на поддержку и развитие талантливых мастеров красоты на международной арене, предоставляя им возможность для творческого и профессионального роста.',
        content4: 'Цель премии: Предоставить платформу для демонстрации мастерства и талантов профессионалов в индустрии красоты со всего мира. Создание условий для обмена передовыми методиками, инновациями и практическими навыками между участниками из разных стран.',
        content5: 'Категории участников:',
        textli1:'«МАСТЕР НОГТЕВОГО СЕРВИСА»',
        textli2:'«ПАРИКМАХЕР»',
        textli3:'«КОСМЕТОЛОГ»',
        textli4:'«МАСТЕР ПЕРМАНЕНТНОГО МАКИЯЖА»',
        textli5:'«ТАТУ МАСТЕР»',
        content6: 'Номинации в каждой категории: «Инновации в процедурах» и «Прорыв года». ',
        content7: 'Участники премии: специалисты с международным признанием, с опытом работы не менее трех лет в индустрии, которые ежегодно проявляют свои таланты, обмениваясь опытом со своими коллегами из разных стран.',
        content8: 'Участники Международной премии “Олимп Красоты” представляют собой яркий пример лучших профессионалов в индустрии красоты, обладающих уникальными навыками и знаниями, накопленными за годы работы на международном уровне. Каждый из них отличается не только высоким уровнем мастерства, но и способностью внедрять инновационные подходы в свою практику. Они приезжают из разных уголков мира, представляя разнообразие культурных и профессиональных традиций, что делает премию по-настоящему уникальной.',
        content9: 'Каждый из участников имеет значительный международный опыт, работая с клиентами и коллегами по всему миру. Они участвовали в многочисленных международных конференциях, семинарах и мастер-классах, где делились своими знаниями и перенимали лучшие практики. Их работы публиковались в авторитетных профессиональных изданиях, а имена стали синонимом качества и инноваций в сфере красоты. Богатый опыт участников позволяет им привносить свежие идеи и передовые методы создание работ для номинаций премии, делая ее площадкой для настоящего обмена знаниями и профессионального роста.',
        content10: 'Участие в Международной премии “Олимп Красоты” считается почетным и престижным достижением. Это не просто премия, а признание высокого уровня профессионализма и творческого подхода. Быть участником такого мероприятия означает получить возможность выступить на одной сцене с лучшими мастерами мира, продемонстрировать свои навыки перед признанными экспертами и широкой аудиторией. Это также шанс получить международное признание, что открывает новые горизонты для профессионального развития и карьерного роста.',
        content11: 'Для многих профессионалов участие в премии становится значимым этапом в их карьере. Оно открывает двери к новым возможностям, включая сотрудничество с ведущими брендами, приглашения на международные мероприятия и семинары. Победа в любой из номинаций премии и признание жюри, состоящего из мировых экспертов, служит мощным стимулом для дальнейшего совершенствования и вдохновения. Участники получают не только ценные призы, но и бесценный опыт, который помогает им достигать новых высот в своей профессии.',
        content12: 'Международная премия "Олимп Красоты" стремится создать вдохновляющую и поддерживающую среду для всех участников, помогая им достигать новых высот в их профессиональной карьере.',
        content13: 'Особенности проведения премии:',
        textli6:'Честная система оценивания: Прозрачный процесс оценки работ, который исключает предвзятость и сохраняет анонимность участников.',
        textli7:'Интерактивные технологии: Применение современных технологий для обеспечения интерактивного взаимодействия между участниками и жюри.',
        content14: 'Дополнительные мероприятия:',
        textli8:'Образовательные сессии: Проведение мастер-классов и вебинаров от ведущих экспертов.',
        textli9:'Обсуждения и дебаты: Дискуссионные панели и круглые столы',
        textli10:'Обратная связь: Сессии с обратной связью от членов жюри.',
        textli11:'Нетворкинг: Специальные сессии для установления профессиональных контактов.',
        content15: 'Процесс подачи заявок:',
        content16: 'Для участия необходимо зарегистрироваться на официальном сайте премии (https://beauty-olymp.com)  с 1 октября до 1 декабря 2019 года. Участники должны предоставить работы, соответствующие требованиям выбранных номинаций.',
        content17: 'Награды:',
        content18: 'Победители получат денежные призы, ценные подарки от спонсоров, сертификаты и возможности для сотрудничества и повышения своего профессионального статуса. Итоги конкурса будут подведены 10 декабря 2019 года.',
        content19: 'Международная премия мастеров Олимп Красоты предлагает уникальную платформу для демонстрации мастерства, обмена опытом и творческого роста, способствуя развитию индустрии красоты на международном уровне. Организаторы премии стремятся к внедрению инноваций и профессиональному взаимодействию в сфере красоты, поддерживая развитие и вдохновение участников.',
    },
  ];

  export default articlesData;
